import { RowNode, ValueFormatterParams, ColGroupDef, ColDef } from 'ag-grid-community';
import { Utilities, DATE_FORMAT, cellStyle } from '@wings-shared/core';

/* istanbul ignore next */
export const auditFields = (isRowEditing): (ColDef | ColGroupDef)[] => {
  return [
    {
      headerName: 'AD',
      groupId: 'auditDetails',
      suppressMenu: true,
      children: [
        {
          headerName: 'Modified By',
          headerTooltip: 'Modified By',
          field: 'modifiedBy',
          headerComponent: 'customHeader',
          minWidth: 90,
          editable: false,
          sortable: true,
          cellEditorParams: {
            getDisableState: (node: RowNode) => isRowEditing,
          },
        },
        {
          headerName: 'Modified On',
          headerTooltip: 'Modified On',
          columnGroupShow: 'open',
          field: 'modifiedOn',
          cellEditor: 'customTimeEditor',
          editable: false,
          sortable: true,
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
          cellEditorParams: {
            getDisableState: (node: RowNode) => isRowEditing,
          },
          comparator: (current: string, next: string) => Utilities.customDateComparator(current, next),
        },
        {
          headerName: 'Created By',
          headerTooltip: 'Created By',
          field: 'createdBy',
          columnGroupShow: 'open',
          editable: false,
          sortable: true,
          cellEditorParams: {
            getDisableState: (node: RowNode) => isRowEditing,
          },
        },
        {
          headerName: 'Created On',
          headerTooltip: 'Created On',
          columnGroupShow: 'open',
          field: 'createdOn',
          cellEditor: 'customTimeEditor',
          editable: false,
          sortable: true,
          valueFormatter: ({ value }: ValueFormatterParams) =>
            Utilities.getformattedDate(value, DATE_FORMAT.API_DATE_FORMAT),
          cellEditorParams: {
            getDisableState: (node: RowNode) => isRowEditing,
          },
          comparator: (current: string, next: string) => Utilities.customDateComparator(current, next),
        },
      ],
    },
  ];
};

export const actionColumn = (colDefData: ColDef) => ({
  headerName: '',
  field: 'actionRenderer',
  cellRenderer: 'actionRenderer',
  cellEditor: 'actionRenderer',
  maxWidth: 150,
  minWidth: 130,
  sortable: false,
  filter: false,
  suppressSizeToFit: true,
  suppressNavigable: true,
  cellStyle: { ...cellStyle() },
  ...colDefData,
});