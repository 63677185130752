import React, { FC, ReactNode } from 'react';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { useStyles } from './AgGridMasterDetails.styles';
import { PrimaryButton, SecondaryButton } from '@uvgo-shared/buttons';
import classNames from 'classnames';
import { IClasses, ViewPermission } from '@wings-shared/core';

type Props = {
  classes?: IClasses;
  addButtonTitle: string;
  hasAddPermission?: boolean;
  disabled?: boolean;
  children: ReactNode;
  onAddButtonClick: Function;
  resetHeight?: boolean;
  isPrimaryBtn?: boolean;
};

const AgGridMasterDetails: FC<Props> = ({
  hasAddPermission,
  addButtonTitle,
  disabled,
  children,
  resetHeight,
  ...props
}) => {
  const classes = useStyles();
  const wrapper = classNames({
    [classes.root]: true,
    [classes.resetHeight]: resetHeight,
  });
  const ActionButton = props.isPrimaryBtn ? PrimaryButton : SecondaryButton;
  return (
    <div className={wrapper}>
      <ViewPermission hasPermission={hasAddPermission}>
        <ActionButton
          className={classes.addButton}
          variant="contained"
          startIcon={<AddIcon />}
          disabled={disabled}
          onClick={() => props.onAddButtonClick()}
        >
          {addButtonTitle}
        </ActionButton>
      </ViewPermission>
      {children}
    </div>
  );
};

export default AgGridMasterDetails;
