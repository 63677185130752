import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    height: '100%',
    maxHeight: 350,
    padding: spacing(2),
    background: palette.background.paper,
  },
  addButton: {
    marginBottom: 10,
  },
  resetHeight: {
    maxHeight: 'unset',
  },
}));
