export const apiUrls = {
  timeZone: 'api/TimeZone',
  airportHoursSchedule: '/api/AirportHours/Schedule',
  state: 'api/State',
  city: 'api/City',
  country: 'api/Country',
  region: 'api/Region',
  metro: 'api/Metro',
  referenceData: 'api/referenceData',
  accessLevel: 'api/AccessLevel',
  sourceType: 'api/SourceType',
  farType: 'api/FARType',
  audit: 'api/AuditHistory',
  bulletin: 'api/Bulletin',
  bulletinLevel: 'api/BulletinLevel',
  bulletinType: 'api/BulletinType',
  bulletinSource: 'api/BulletinSource',
};
