import React, { Component, ChangeEvent, ReactNode, FC } from 'react';
import { AgGridReact as AgGridReactComponent, AgGridReactProps } from 'ag-grid-react';
import { withStyles } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { styles } from './AgGridReact.styles';
import { GridPagination } from '../../Models';
import classNames from 'classnames';
import 'ag-grid-enterprise';
import { RowClickedEvent } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import { IClasses } from '@wings-shared/core';

interface Props extends AgGridReactProps {
  serverPagination?: boolean;
  paginationData?: GridPagination;
  classes?: IClasses;
  onPaginationChange?: (request: { pageNumber: number; pageSize: number }) => void;
  onRowClicked?: (event: RowClickedEvent) => void;
  isRowEditing?: boolean;
  hasFooterActions?: boolean;
  disablePagination?: boolean;
  rowsPerPageOptions?: Array<number | { value: number; label: string }>;
  customRowsPerPageLabel?: string;
}

class CustomAgGridReact extends Component<Props> {
  /* istanbul ignore next */
  static defaultProps = {
    serverPagination: false,
    rowsPerPageOptions: [ 10, 20, 30, 50, 100 ],
    onPaginationChange: (request: { pageNumber: number; pageSize: number }) => null,
    onRowClicked: (event: RowClickedEvent) => null,
    paginationData: {
      pageNumber: 1,
      pageSize: 30,
      totalNumberOfRecords: 0,
    },
    customRowsPerPageLabel: 'Rows per page',
  };

  private onChangePage(pageNumber: number, pageSize: number): void {
    if (pageNumber) {
      this.props.onPaginationChange({ pageNumber, pageSize });
    }
  }

  private get pagination(): ReactNode {
    if (!this.props.serverPagination) {
      return null;
    }

    const { paginationData, classes, disablePagination, rowsPerPageOptions, customRowsPerPageLabel } = this.props;
    const { totalNumberOfRecords, pageSize, pageNumber } = paginationData;

    return (
      <TablePagination
        component="div"
        className={classes.pagination}
        count={totalNumberOfRecords}
        rowsPerPage={pageSize}
        labelRowsPerPage={<>{customRowsPerPageLabel} :</>}
        labelDisplayedRows={p => (
          <div className={classes.labelDisplayedRows}>
            <div>Total Records :  {totalNumberOfRecords}</div>
            <div>
              Page {p.page + 1} of {Math.ceil(totalNumberOfRecords / pageSize)}
            </div>
          </div>
        )}
        rowsPerPageOptions={rowsPerPageOptions}
        classes={{ root: classNames({ [classes.disablePagination]: disablePagination }) }}
        page={pageNumber - 1}
        onPageChange={(_, page) => this.onChangePage(page + 1, pageSize)}
        onRowsPerPageChange={({ target }: ChangeEvent<HTMLInputElement>) => this.onChangePage(1, Number(target.value))}
      />
    );
  }

  render() {
    const {
      gridOptions,
      rowData,
      classes,
      serverPagination,
      isRowEditing,
      hasFooterActions,
      onRowClicked,
      disablePagination,
    } = this.props;
    const gridClass = classNames({
      'ag-theme-alpine': true,
      [classes.gridContainer]: true,
      [classes.serverPagination]: serverPagination,
      [classes.rowEditing]: isRowEditing,
      [classes.footerActions]: hasFooterActions,
      [classes.disablePagination]: disablePagination,
      // This class will be passed from parent component
      [classes.customHeight]: true,
    });

    return (
      <div className={gridClass}>
        <AgGridReactComponent
          tooltipShowDelay={0}
          gridOptions={gridOptions}
          rowData={rowData}
          masterDetail={true}
          onRowClicked={event => onRowClicked(event)}
        />
        {this.pagination}
      </div>
    );
  }
}

export default withStyles(styles)(CustomAgGridReact) as FC<Props>;
