import { createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& input[type=checkbox][readonly]': {
        cursor: 'default',
      },
      '& [class*="MuiButtonBase-root"]': {
        height: '100%',
        width: '100%',
        borderRadius: '0px',
      },
    },
    input: {
      justifyContent: 'left',
    },
  });
