import { createStyles, Theme } from '@material-ui/core/styles';

export const getBaseActionsStyles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 40,
      padding: `${theme.spacing(1)}px !important`,
    },
    buttonContainer: {
      display: 'flex',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      '& $root': {
        marginLeft: theme.spacing(1),
      },
    },
  });
