import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { AxiosError } from 'axios';
import { AlertStore } from '@uvgo-shared/alert';
import { Observable } from 'rxjs';
import { SettingTypeBase } from '../SettingsBase/SettingsBase';
import { SettingsType } from '../SettingsType/SettingsType';
import { UIStore, Loader, SettingsTypeModel } from '@wings-shared/core';

interface Props {
  getSettings?: () => Observable<SettingsTypeModel[]>;
  upsertSettings?: (object: SettingsTypeModel) => Observable<SettingsTypeModel>;
  settingsData: SettingsTypeModel[];
  type: string;
  loader?: Loader;
  isNameUnique?: boolean;
  isEditable?: boolean;
  hideAddNewButton?: boolean;
  maxLength?: number;
  regExp?: RegExp;
  isHideSearchSelectControl?: boolean;
  ignoreNumber?: boolean;
  headerName?: string;
  hasSuperPermission?: boolean;
  onDelete?: (object: SettingsTypeModel) => Observable<string>
  showDeleteButton?: boolean;
}

@observer
class UpsertSettings extends SettingTypeBase<Props> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loadInitialData();
  }

  /* istanbul ignore next */
  private loadInitialData(): void {
    UIStore.setPageLoader(true);
    this.props
      .getSettings()
      .pipe(
        takeUntil(this.destroy$),
        tap((response: SettingsTypeModel[]) => this.settingsTypesRef.current?.setData(response)),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe();
  }

  /* istanbul ignore next */
  private upsertSettings(rowIndex: number, model: SettingsTypeModel): void {
    UIStore.setPageLoader(true);
    this.props
      .upsertSettings(model)
      .pipe(
        takeUntil(this.destroy$),
        tap((response: SettingsTypeModel) => this.settingsTypesRef.current?.updateTableItem(rowIndex, response)),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        error: (error: AxiosError) => {
          AlertStore.critical(error.message);
        },
      });
  }

  public render(): ReactNode {
    const {
      settingsData,
      type,
      isNameUnique,
      isEditable,
      hideAddNewButton,
      maxLength,
      regExp,
      isHideSearchSelectControl,
      ignoreNumber,
      headerName,
      hasSuperPermission,
      onDelete,
      showDeleteButton,
    } = this.props;
    return (
      <>
        <SettingsType
          ref={this.settingsTypesRef}
          rowData={settingsData}
          onGetNewModel={() => new SettingsTypeModel({ id: 0 })}
          onUpsert={(rowIndex: number, data: SettingsTypeModel) => this.upsertSettings(rowIndex, data)}
          onDelete={onDelete}
          showDeleteButton={showDeleteButton}
          type={type}
          isNameUnique={isNameUnique}
          isEditable={isEditable}
          hasSuperPermission={hasSuperPermission}
          hideAddNewButton={hideAddNewButton}
          maxLength={maxLength}
          regExp={regExp}
          ignoreNumber={ignoreNumber}
          isHideSearchSelectControl={isHideSearchSelectControl}
          headerName={headerName}
        />
      </>
    );
  }
}

export default UpsertSettings;
