import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { ColDef, GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { useAgGrid, CustomAgGridReact, useGridState, agGridUtilities, VIEW_MODE } from '@wings/shared';
import { observer, inject } from 'mobx-react';
import { useUnsubscribe } from '@wings-shared/hooks';
import { finalize, takeUntil } from 'rxjs/operators';
import { GridPagination, IAPIGridRequest, UIStore, GRID_ACTIONS, Utilities } from '@wings-shared/core';
import { BulletinModel } from './Models';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import { CustomLinkButton } from '@wings-shared/layout';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { BulletinStore } from './Stores/Bulletin.store';
import { BULLETIN_FILTERS } from './Enums';
import { gridFilters } from './fields';

interface Props {
  bulletinStore?: BulletinStore;
  securityModule: any;
  filters?: any;
}

const Bulletins: FC<Props> = observer(({ bulletinStore, securityModule }) => {
  const unsubscribe = useUnsubscribe();
  const gridState = useGridState();
  const agGrid = useAgGrid<BULLETIN_FILTERS, BulletinModel>(gridFilters, gridState);
  const searchHeaderRef = useRef<ISearchHeaderRef>();

  // Load Data on Mount
  useEffect(() => {
    gridState.setPagination(new GridPagination());
    loadInitialData();
  }, []);

  const getFilterCollection = (): IAPIGridRequest => {
    if (!searchHeaderRef.current.searchValue) {
      return {};
    }
    const property = gridFilters.find(({ uiFilterType }) =>
      Utilities.isEqual(uiFilterType as string, searchHeaderRef.current.selectedOption)
    );
    const searchFilterKey = `${property?.columnId}Name` || '';
    const searchFilter = { [searchFilterKey]: searchHeaderRef.current.searchValue };
    return {
      filterCollection: JSON.stringify([
        {
          ...searchFilter,
        },
      ]),
    };
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...getFilterCollection(),
      //...agGrid.filtersApi.gridSortFilters(),
    };
    UIStore.setPageLoader(false);
    bulletinStore
      ?.getBulletins(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        gridState.setPagination(new GridPagination({ ...response }));
        gridState.setGridData(response.results);
      });
  };

  /* istanbul ignore next */
  const columnDefs: ColDef[] = [
    {
      headerName: 'Bulletin Level',
      field: 'bulletinLevel',
      headerTooltip: 'Bulletin Level',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Bulletin Entity',
      field: 'bulletinEntity',
      headerTooltip: 'Bulletin Entity',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Bulletin Source',
      field: 'bulletinSource',
      headerTooltip: 'Bulletin Source',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'UAOffice',
      field: 'uaOffice',
      headerTooltip: 'UAOffice',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
      headerTooltip: 'Start Date',
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      headerTooltip: 'End Date',
    },
    {
      headerName: 'Status',
      field: 'status',
      headerTooltip: 'Status',
      valueFormatter: ({ value }: ValueFormatterParams) => value?.label || '',
    },
    ...agGrid.auditFields(gridState.isRowEditing),
    {
      ...agGrid.actionColumn({
        cellRendererParams: {
          isActionMenu: true,
          actionMenus: () => [
            {
              title: 'Edit',
              isHidden: !securityModule.isEditable,
              action: GRID_ACTIONS.EDIT,
              to: node => `${node.data?.id}/${VIEW_MODE.EDIT.toLocaleLowerCase()}`,
            },
            {
              title: 'Details',
              action: GRID_ACTIONS.DETAILS,
              to: node => `${node.data?.id}/${VIEW_MODE.DETAILS.toLocaleLowerCase()}`,
            },
          ],
        },
      }),
    },
  ];

  /* istanbul ignore next */
  const gridOptions = (): GridOptions => {
    const baseOptions: Partial<GridOptions> = agGrid.gridOptionsBase({
      context: {},
      columnDefs,
    });

    return {
      ...baseOptions,
      pagination: false,
      suppressRowClickSelection: true,
      suppressCellSelection: true,
      isExternalFilterPresent: () => false,
      onFilterChanged: () => loadInitialData({ pageNumber: 1 }),
      onSortChanged: e => {
        agGrid.filtersApi.onSortChanged(e);
        loadInitialData({ pageNumber: 1 });
      },
    };
  };

  const rightContent = (): ReactNode => {
    if (!securityModule.isEditable) {
      return null;
    }

    return <CustomLinkButton variant="contained" startIcon={<AddIcon />} to="new" title="Add Bulletin" />;
  };

  return (
    <>
      <SearchHeaderV2
        ref={searchHeaderRef}
        selectInputs={[
          agGridUtilities.createSelectOption(BULLETIN_FILTERS, BULLETIN_FILTERS.BULLETIN_LEVEL, 'defaultOption'),
        ]}
        rightContent={rightContent}
        onFilterChange={isInitEvent =>
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 })
        }
        onExpandCollapse={agGrid.autoSizeColumns}
      />
      <CustomAgGridReact
        isRowEditing={gridState.isRowEditing}
        rowData={gridState.data}
        gridOptions={gridOptions()}
        serverPagination={true}
        paginationData={gridState.pagination}
        onPaginationChange={loadInitialData}
      />
    </>
  );
});

export default inject('bulletinStore')(Bulletins);
