import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseStore } from './Base.store';
import { IAPIAirport } from '../Interfaces';
import { HttpClient } from '../Tools';
import { NO_SQL_COLLECTIONS } from '../Enums';
import { AirportModel } from '../Models';
import { observable } from 'mobx';
import { apiUrls } from './ApiUrls';
import { baseApiPath } from '../API';
import { Logger } from '@wings-shared/security';
import { IAPIGridRequest, IAPIPageResponse, Utilities, tapWithAction } from '@wings-shared/core';

export class BaseAirportStore extends BaseStore {
  @observable public wingsAirports: AirportModel[] = [];

  /* istanbul ignore next */
  public getWingsAirports(request?: IAPIGridRequest): Observable<IAPIPageResponse<AirportModel>> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.noSqlData });
    const params: string = Utilities.buildParamString({
      pageNumber: 1,
      pageSize: 30,
      collectionName: NO_SQL_COLLECTIONS.Airports,
      sortCollection: JSON.stringify([{ propertyName: 'Name', isAscending: true }]),
      ...request,
    });
    return http.get<IAPIPageResponse<IAPIAirport>>(`${apiUrls.referenceData}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => {
        return { ...response, results: AirportModel.deserializeList(response.results) };
      })
    );
  }

  public searchWingsAirports(searchValue: string, excludeRetail?: boolean): Observable<AirportModel[]> {
    if (!searchValue) {
      this.wingsAirports = [];
      return of([]);
    }
    const filter = Utilities.getFilter('Status.Name', 'Active', 'and');
    const request: IAPIGridRequest = {
      pageSize: 50,
      searchCollection: JSON.stringify([
        Utilities.getFilter('Name', searchValue),
        Utilities.getFilter('ICAOCode.Code', searchValue, 'or'),
        Utilities.getFilter('UWACode', searchValue, 'or'),
      ]),
      filterCollection: JSON.stringify(
        excludeRetail
          ? [{ propertyName: 'AppliedAirportUsageType.AirportUsageType.Name', propertyValue: 'Operational' }, filter ]
          : [ filter ]
      ),
      specifiedFields: [ 'AirportId', 'ICAOCode', 'UWACode', 'IATACode', 'Name', 'CappsAirportName' ],
    };
    return this.getWingsAirports(request).pipe(
      map(response => response.results),
      tapWithAction(airports => (this.wingsAirports = airports))
    );
  }

  // Search Airport By Code
  public searchWingsAirportsByCode(
    searchValue: string,
    options?: { excludeRetail?: boolean }
  ): Observable<AirportModel[]> {
    if (!searchValue) {
      this.wingsAirports = [];
      return of([]);
    }
    const request: IAPIGridRequest = {
      pageSize: 50,
      searchCollection: JSON.stringify([
        Utilities.getFilter('ICAOCode.Code', searchValue, 'and'),
        Utilities.getFilter('UWACode', searchValue, 'or'),
        Utilities.getFilter('RegionalCode', searchValue, 'or'),
        Utilities.getFilter('IATACode', searchValue, 'or'),
        Utilities.getFilter('FAACode', searchValue, 'or'),
      ]),
      filterCollection: JSON.stringify(
        options?.excludeRetail
          ? [{ propertyName: 'AppliedAirportUsageType.AirportUsageType.Name', propertyValue: 'Operational' }]
          : []
      ),
      specifiedFields: [
        'AirportId',
        'ICAOCode',
        'UWACode',
        'FAACode',
        'RegionalCode',
        'IATACode',
        'Name',
        'CappsAirportName',
        'Status',
      ],
    };
    return this.getWingsAirports(request).pipe(
      map(response => response.results),
      tapWithAction(airports => (this.wingsAirports = airports))
    );
  }
}
